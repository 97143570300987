import UserService from '../services/user.service';
import AuthService from '@/services/auth.service';

const initialState = {
    users: [],
    user:null,
    reset: false,
    userProfile: null
}
export const user = {
    namespaced: true,
    state: initialState,
    actions: {
        async getUsers({ commit }) {
            let allUsers = await UserService.getUsers();
            commit('populateUsers', allUsers.data.users);
          },
          async getUser({ commit }, userEmail) {
            let userResponse = await UserService.getUser(userEmail);
            commit('populateUserProfile', userResponse.data.user);
          },
          async sendEmail({commit}, userEmail){
            await UserService.sendEmail(userEmail)
            commit('passwordReset')
          },
          async remove(user){
            await AuthService.delete(user)
          },
          async updateProfile({commit}, userArgs) {
            await UserService.updateProfile(userArgs);
            commit('populateUserProfile', userArgs);
          }
    },
    mutations: {
        populateUsers(state, users) {
            state.users = users;
          },
          passwordReset(state) {
            state.reset = true
          },
          populateUserProfile(state, profile) {
            state.userProfile = profile;
          }
    },
}