<template>
    <div class="navbar-nav mr-auto">
      <li class="nav-item">
        <router-link to="/login" class="nav-link">
          <font-awesome-icon icon="sign-in-alt" class="members-icon" />
          <span v-if="this.windowWidth > 415">&nbsp;Members</span>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link to="/enquiry" class="nav-link">
          <font-awesome-icon icon="contact-card" class="enquiry-icon" />
          <span v-if="this.windowWidth > 415">&nbsp;Contact</span>
        </router-link>
      </li>
    </div>
  </template>
  
  <script>
  export default {
    name: 'NavBarLoggedOutView',
    computed: {
      windowWidth() {
        return this.$store.state.resizer.windowWidth;
      },
      basedOnWindowWidth() {
        return this.windowWidth > 768;
      }
    },
    mounted() {
      window.addEventListener('resize', this.resizeMethod);
    },
    methods: {
      resizeMethod() {
        this.$store.commit('resizer/setWindowWidth');
      }
    }
  };
  </script>
  
  <style>
  .navbar-nav {
    display: flex;
    align-items: center;
  }
  
  .nav-item {
    list-style: none;
    margin-right: 1.25rem;
  }
  
  .nav-link {
    color: #007bff;
    text-decoration: none;
  }
  
  .nav-link:hover {
    color: #0056b3;
  }
  
  .home-container {
    display: flex;
    align-items: center;
  }
  
  .brox-img {
    height: 30px;
  }
  
  .members-icon,
  .enquiry-icon {
    margin-right: 0.5rem;
  }
  </style>