import RoleService from '../services/role.service';

const initialState = {
    roles: [],
}
export const role = {
    namespaced: true,
    state: initialState,
    actions: {
        async getRoles({ commit }) {
            let allRoles = await RoleService.getRoles();
            commit('populateRoles', allRoles.data.roles);
          }
    },
    mutations: {
        populateRoles(state, roles) {
            state.roles = roles;
          }
    },
}