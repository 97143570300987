const initialState = {
    windowWidth: window.innerWidth
}
export const resizer = {
    namespaced: true,
    state: initialState,
    mutations: {
        setWindowWidth(state) {
            state.windowWidth = window.innerWidth;
          }
    },
}