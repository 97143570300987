import axios from 'axios';
import authHeader from './auth-header';

const API_URL = 'https://broxtech-backend-ced04e937784.herokuapp.com/document';
const USERS_API_URL = 'https://broxtech-backend-ced04e937784.herokuapp.com/users';
const ORG_API_URL = 'https://broxtech-backend-ced04e937784.herokuapp.com/organisation-documents';

class documentService {
  async getAllDocuments() {
    return await axios.get(API_URL, { headers: authHeader() });
  }
  async getUserDocuments(){
    return await axios.get(`${USERS_API_URL}/user/documents`, { headers: authHeader() })
  }
  async getOrganisationDocuments(uri){
    return await axios.get(ORG_API_URL + '/' + uri, { headers: authHeader() })
  }
  async upload(files) {
    var formData = new FormData();
    formData.append('file', files);
    const response = await axios.post(API_URL, formData, { headers: authHeader() });
    return response.data;
  }
  async uploadPersonalDocument(files){
    var formData = new FormData();
    formData.append('file', files);
    const response = await axios.post(API_URL + '/personal-documents', formData, { headers: authHeader() });
    return response.data; 
  }
  async uploadSubscriberDocument(files){
    var formData = new FormData();
    formData.append('file', files);
    const response = await axios.post(API_URL + '/subscriber-documents', formData, { headers: authHeader() });
    return response.data;     
  }
  async updateSubscriberDocumentText(fileId, text) {
    const response = await axios.put(`${API_URL}/save-document-text`, {fileId,text}, {
      headers: {
        ...authHeader(),
        'Content-Type': 'application/json'
      }
    });
    return response.data;
  }
  async uploadOrganisationDocument(files, uri) {
    var formData = new FormData();
    formData.append('file', files);
    const response = await axios.post(ORG_API_URL + '/' + uri, formData, { headers: authHeader() });
    return response.data.text();
  }
  async deleteOrganisationDocument(file){
    let file_id = file.document_id
    await axios.delete(ORG_API_URL + '/delete/' + file_id, { headers: authHeader() });
  }
  async deleteSubscriberDocument(document) {
    const response = await axios.delete(API_URL + '/remove-subscriber-document/' + document.document_id, { headers: authHeader() });
    return response.data;
  }
  async readDocument(fileId){
    return await axios.get(API_URL + '/read/' + fileId, { headers: authHeader() });
  }
  async download(file){
    let file_id = file.document_id
    let response = await axios({
      url: API_URL + '/download/' + file_id,
      headers: authHeader(),
      method: 'GET',
      responseType: 'blob', // important
    });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', file.filename);
    document.body.appendChild(link);
    link.click();
  }
  async delete(file){
    let file_id = file.document_id
    await axios.delete(API_URL + '/delete/' + file_id, { headers: authHeader() });
  }
}
export default new documentService();