<template>
  <div class="home">
    <div class="brox-headers">
      <h1 class="app-h1">Broxtech</h1>
    </div>
    <div class="main-content">
      <div class="upper">
        <p>Broxtech offers easy to use and secure file management.
        Whether you're working solo or part of a team we offer ease, flexibility and control.</p>
      </div>
     <div v-if="isWideScreen" class="middle" :style="{ fontSize: computedFontSize }">
      <ul>
          <li><font-awesome-icon icon="file" />&nbsp; Document Management</li>
          <li><font-awesome-icon icon="user" />&nbsp; Personnel Management</li>
          <li><font-awesome-icon icon="globe" />&nbsp; Web Development</li>
          <li><font-awesome-icon icon="file-contract" />&nbsp; Contract and Consultancy</li>
        </ul>
     </div>
     <div class="lower">
      <RegisterView />
      <router-link to="/products" class="nav-link">
        Ready? Choose a product and subscription that suits your needs
      </router-link>
      <router-link to="/enquiry" class="nav-link">
        Have some questions? Please get in touch!
      </router-link>
     </div>
    </div>

    <div class="image-footer">
      <!-- <img :class="windowWidth>768?'brox-img-wide':'brox-img-small'" src="../../assets/logo.png"/> -->
    </div>
  </div>
</template>
<script>
import RegisterView from './RegisterView.vue';
export default {
  name: "HomeView",
  components: {
    RegisterView
  },
  computed:{
    windowWidth() {
      return this.$store.state.resizer.windowWidth;
    },
    computedFontSize() {
      if (this.windowWidth > 1000) {
        return '1.5rem';
      } else if (this.windowWidth > 600) {
        return '1.25rem';
      } else {
        return '1rem';
      }
    },
    isWideScreen() {
      return this.windowWidth > 800;
    }
  },
  data() {
    return {
      content: "Welcome to Broxtech",
    };
  },
  mounted() {
    
  },
};
</script>

<style scoped>

.home{
    font-family: "Roboto Slab";
    font-size: x-large;
    background-color: rgba(255,255,255, 0.9);
    padding:1rem;
    border-radius: 1rem;
    box-shadow: 0.3rem 0.4rem 0.4rem #3633338c;
}

.home:hover{
  background-color: rgb(250, 250, 250, 0.95);
  box-shadow: 0.4rem 0.6rem 0.6rem #1816168c;
}


img{
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 10%;
}
.image-footer-wide{
  /* bring your own prefixes */
  border: 1rem solid black;
  /* box-shadow: 0.1rem 0.1rem rgba(255,255,255,0.5); */
  border-radius: 1rem;
  width:50rem;
}

.upper {
  margin-bottom: 1.25rem; /* 20px to rem */
  border: 0.0625rem solid rgba(0, 0, 0, 0.1); /* 1px to rem */
  padding: 1.25rem; /* 20px to rem */
  border-radius: 0.3125rem; /* 5px to rem */
  background-color: rgba(255, 255, 255, 0.9);
}

.middle {
  border: 0.0625rem solid rgba(0, 0, 0, 0.1); /* 1px to rem */
  padding: 1.25rem; /* 20px to rem */
  border-radius: 0.3125rem; /* 5px to rem */
  background-color: rgba(245, 245, 245, 0.9); /* Very slightly gray background */
}

.middle ul {
  display: flex; /* Align items horizontally */
  list-style: none; /* Remove bullet points */
  padding-left: 0; /* Remove default padding */
  gap: 1.25rem; /* 20px to rem */
}

.middle li {
  display: flex;
  align-items: center;
}

.lower {
  margin-top: 1.25rem; /* 20px to rem */
  border: 0.0625rem solid rgba(0, 0, 0, 0.1); /* 1px to rem */
  padding: 1.25rem; /* 20px to rem */
  border-radius: 0.3125rem; /* 5px to rem */
  background-color: rgba(255, 255, 255, 0.9);
}

.brox-img-wide{
  width:80%;
  border: 1rem solid black;
  border-radius: 1rem;
}

.brox-img-small{
  width:80%;
  border: 1rem solid black;
  border-radius: 1rem;
}

.brox-img-wide:hover{
  cursor: pointer;
  background-color: rgba(255,255,255,0.1);
}

.brox-img-small:hover{
  cursor: pointer;
  background-color: rgba(255,255,255,0.1);
}


.nav-link {
  display: block;
  margin-bottom: 0.625rem; /* 10px to rem */
  padding: 0.625rem; /* 10px to rem */
  border: 0.0625rem solid rgba(0, 0, 0, 0.1); /* 1px to rem */
  border-radius: 0.3125rem; /* 5px to rem */
  background-color: rgba(255, 255, 255, 0.9);
  color: black; /* Plain black text color */
  text-decoration: none;
  transition: background-color 0.3s;
}

.nav-link:hover {
  background-color: rgba(245, 245, 245, 0.9); /* Slight gray background on hover */
}

.nav-link:active {
  background-color: rgba(230, 230, 230, 0.9); /* Slightly darker gray background when active */
}

.image-footer {
  margin-top: 1.25rem; /* 20px to rem */
  text-align: center;
}

</style>
