import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from './plugins/font-awesome'
// import { StripePlugin } from '@vue-stripe/vue-stripe';

// const options = {
//   pk: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
//   stripeAccount: process.env.VUE_APP_STRIPE_ACCOUNT,
//   apiVersion: process.env.API_VERSION,
//   locale: process.env.LOCALE,
// };

createApp(App)
// .use(StripePlugin, options)
.use(store)
.use(router)
.component("font-awesome-icon", FontAwesomeIcon)
.mount('#app')
