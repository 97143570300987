import ProductService from '../services/product.service';

const initialState = {
    products: [],
}
export const products = {
    namespaced: true,
    state: initialState,
    actions: {
        async getProducts({ commit }) {
            let allProducts = await ProductService.getProducts();
            commit('populateProducts', allProducts.data.products);
        },
        async getUserProducts({commit}, userId){
            let userProducts = await ProductService.getUserProducts(userId)
            commit('populateProducts', userProducts.data.user_products)
        } 
    },
    mutations: {
        populateProducts(state, products) {
            state.products = products;
          }
    },
}