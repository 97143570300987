<template>
  <div class="register-view">
    <h4>Sign up now for a free 10GB of document storage</h4>
    <Form @submit="handleRegister" :validation-schema="schema">
      <div class="form-group-inline">
        <label for="email">Email</label>
        <Field name="email" type="email" class="form-control" />
        <ErrorMessage name="email" class="error-message" />
        <button type="submit" class="btn btn-primary" :disabled="loading">Sign Up</button>
      </div>
      <div v-if="message" class="alert" :class="successful ? 'alert-success' : 'alert-danger'">
        {{ message }}
      </div>
    </Form>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";

export default {
  name: "RegisterView",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    const schema = yup.object().shape({
      email: yup
        .string()
        .required("Email is required!")
        .email("Email is invalid!")
        .max(50, "Must be maximum 50 characters!"),
    });

    return {
      schema,
      message: "",
      successful: false,
      loading: false,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  mounted() {
    if (this.loggedIn) {
      this.$router.push("/profile");
    }
  },
  methods: {
    async handleRegister(user) {
      this.message = "Registering Account";
      this.successful = false;
      this.loading = true;
      try {
        this.message = 'if this worked an email gets sent';
        await this.$store.dispatch("auth/register", user);
        this.message = "Registration almost complete, an email has been sent to verify.";
        this.successful = true;
      } catch (error) {
        this.message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        this.successful = false;
      }
      this.loading = false;
    },
  },
};
</script>

<style scoped>
.register-view {
  max-width: 50rem;
  margin: 0 auto;
  padding: 1.25rem; /* 20px to rem */
  border: 0.0625rem solid #ccc; /* 1px to rem */
  border-radius: 0.3125rem; /* 5px to rem */
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.1); /* 2px 4px to rem */
  background-color: #fff;
}

.form-group-inline {
  display: flex;
  align-items: center;
  gap: 1rem; /* 16px to rem */
  margin-bottom: 1rem; /* 16px to rem */
}


.error-message {
  color: red;
  font-size: 0.875rem; /* 14px to rem */
}

.alert {
  margin-top: 1rem; /* 16px to rem */
  padding: 0.75rem 1.25rem;
  border: 0.0625rem solid transparent; /* 1px to rem */
  border-radius: 0.3125rem; /* 5px to rem */
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.btn-primary {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 0.3125rem; /* 5px to rem */
  cursor: pointer;
  transition: background-color 0.3s;
}

.btn-primary:hover {
  background-color: #0056b3;
}
</style>