import axios from 'axios';
import authHeader from './auth-header';

const ROLES_API_URL = 'https://broxtech-backend-ced04e937784.herokuapp.com/roles';

class RoleService {
  getRoles() {
    return axios.get(ROLES_API_URL, { headers: authHeader() });
  }
}
export default new RoleService();
